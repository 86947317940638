import React from "react";

export default function DownloadSpinner() {
  return (
    <svg
      width="21.166664mm"
      height="21.166666mm"
      viewBox="0 0 21.166664 21.166666"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs id="defs1" />
      <g id="layer1" transform="translate(-56.782406,-60.413624)">
        <rect
          style={{
            fill: "#3baefa",
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: 1.34978,
            strokeLinecap: "round",
            strokeMiterlimit: 12.4,
            strokeDasharray: "1.34978, 2.69957",
            paintOrder: "stroke fill markers",
          }}
          id="rect1"
          width="21.166666"
          height="21.166666"
          x="56.782406"
          y="60.413624"
          rx="1.6"
          ry="1.6"
        />
        <path
          id="spinner"
          className="spinner-rotate"
          style={{
            fill: "none",
            fillRule: "evenodd",
            stroke: "#ffffff",
            strokeWidth: 2.13334,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 12.4,
            strokeDasharray: "none",
            paintOrder: "stroke fill markers",
          }}
          d="m 64.284275,67.93048 3.080949,3.228227 3.208072,-3.228227 m 0,0 h -6.289021 m 3.080949,3.228227 -3.080949,3.228226 h 6.289021 z"
        />
      </g>
    </svg>
  );
}
