import React, { useEffect, useState, useContext } from "react";
import { GlobalStateContext } from "./GlobalStateContext";
import DownloadSpinner from "./DownloadSpinner";
import DownloadIcon from "./DownloadIcon";

function DownloadBox({ image }) {
  const [imageUrl, setImageUrl] = useState("");
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const { quality } = useContext(GlobalStateContext);

  useEffect(() => {
    const url = URL.createObjectURL(image);
    setImageUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [image]);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleDownload() {
    setIsLoading(true);

    const worker = new Worker(new URL("./imageWorker.js", import.meta.url));

    worker.onmessage = function (e) {
      const { blob, imageName } = e.data;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${imageName}.webp`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
      setIsPressed(true);
    };

    worker.postMessage({
      imageUrl,
      imageName: image.name.split(".")[0],
      quality: quality,
    });
  }

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <div
        className={`d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start position-relative border rounded p-2 py-4 m-1 py-md-2 ${
          isNarrowScreen ? "w-100" : "w-75"
        }`}
      >
        <img
          src={imageUrl}
          className="drink-me me-3 mb3 mb-md-0"
          alt={image.name}
          title={image.name}
        />
        <div className="text-center text-md-start flex-grow-1">
          {isNarrowScreen && <br></br>}
          <h5 className="mt-0">{image.name}</h5>
          <p className="mb-0">{(image.size / 1000).toFixed(2)} kB</p>
        </div>
        <div className="my-md-0 my-3">
          {isLoading ? (
            <DownloadSpinner />
          ) : (
            <div
              title={`Download ${image.name} as WebP`}
              className="download-icon"
              onClick={handleDownload}
            >
              <DownloadIcon isPressed={isPressed} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DownloadBox;
