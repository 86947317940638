import React from "react";

function DownloadIcon({ isPressed }) {
  return (
    <svg
      width="21.166662mm"
      height="21.166668mm"
      viewBox="0 0 21.166662 21.166668"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs1" />
      <g id="layer1" transform="translate(-56.782407,-60.413626)">
        <rect
          className={isPressed ? "fill-animate" : ""}
          style={{
            fill: "#3baefa",
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: 1.34978,
            strokeLinecap: "round",
            strokeMiterlimit: 12.4,
            strokeDasharray: "1.34978, 2.69957",
            paintOrder: "stroke fill markers",
          }}
          id="rect1"
          width="21.166666"
          height="21.166666"
          x="56.782406"
          y="60.413624"
          rx="1.6"
          ry="1.6"
          alt="Download Icon"
          title="Download"
        />
        <path
          id="basin"
          style={{
            fill: "none",
            fillRule: "evenodd",
            stroke: "#ffffff",
            strokeWidth: 2.13334,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 12.4,
            strokeDasharray: "none",
            paintOrder: "stroke fill markers",
          }}
          d="m 75.037345,74.360772 v 3.324484 H 59.694137 v -3.324484"
          alt="Download Icon"
          title="Download"
        />
        <path
          id="chevron"
          className={isPressed ? "chevron-pressed" : ""}
          style={{
            fill: "none",
            fillRule: "evenodd",
            stroke: "#ffffff",
            strokeWidth: 2.13334,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 12.4,
            strokeDasharray: "none",
            paintOrder: "stroke fill markers",
          }}
          d="m 70.510084,66.034277 -3.207691,3.22842 -3.080999,-3.22842"
          alt="Download Icon"
          title="Download"
        />
      </g>
    </svg>
  );
}

export default DownloadIcon;
