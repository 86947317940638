import React, { createContext, useState } from "react";

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [quality, setQuality] = useState(0.85); // Default value

  return (
    <GlobalStateContext.Provider value={{ quality, setQuality }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
