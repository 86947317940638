import "./App.css";
import Container from "./Container.js";
import { GlobalStateProvider } from "./GlobalStateContext";

function App() {
  return (
    <div className="App">
      <GlobalStateProvider>
        <Container />
      </GlobalStateProvider>
    </div>
  );
}

export default App;
