import React, { useContext, useState } from "react";
import DownloadBox from "./DownloadBox";
import { GlobalStateContext } from "./GlobalStateContext";

function SnippyArea() {
  const [isDragging, setIsDragging] = useState(false);
  const { quality, setQuality } = useContext(GlobalStateContext);
  const [images, setImages] = useState([]);
  const [inputQuality, setInputQuality] = useState(quality);

  const handleDragOver = (e) => {
    e.preventDefault();
    if (isImageFile(e.dataTransfer)) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    if (isImageFile(e.dataTransfer)) {
      const files = e.dataTransfer.files;
      handleFiles(files);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (
      value === "" ||
      (parseFloat(value) >= 0.0 && parseFloat(value) <= 1.0)
    ) {
      setInputQuality(value === "" ? "" : parseFloat(value));
    }
    handleQualityChange(value);
  };

  const handleQualityChange = (e) => {
    const value = e;
    if (
      value === "" ||
      (parseFloat(value) >= 0.0 && parseFloat(value) <= 1.0)
    ) {
      setQuality(value === "" ? 0.9 : parseFloat(value));
    }
  };

  const isImageFile = (dataTransfer) => {
    const { items } = dataTransfer;
    for (let i = 0; i < items.length; i++) {
      console.log(items[i].type);
      if (
        items[i].kind === "file" &&
        items[i].type.startsWith("image/") &&
        items[i].type !== "image/gif"
      ) {
        return true;
      }
    }
    return false;
  };
  const handleFiles = (files) => {
    const filesArray = Array.from(files);
    const filteredFiles = filesArray.filter(
      (file) => file.type !== "image/gif"
    );
    filteredFiles.forEach((file) => {
      console.log(file);
    });
    setImages((prevImages) => [...prevImages, ...filteredFiles]);
  };

  const handleReset = () => {
    setImages([]);
    setInputQuality(0.85);
    setQuality(0.85);
    const fileInput = document.getElementById("imageInput");
    if (fileInput) {
      fileInput.value = ""; // Reset the file input
    }
    const snippyDiv = document.getElementById("snippyArea");
    if (snippyDiv) {
      snippyDiv.scrollIntoView({ behavior: "smooth" }); // Scroll to the top of the snippy div
    }
  };

  return (
    <div
      className={`snippy p-3 p-md-3 p-lg-5  ${isDragging ? "dragging" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      id="snippyArea"
    >
      <div className="d-flex align-items-center justify-content-center pb-2">
        <div className="row">
          <p className="fs-3">
            Drag item here or choose files to begin conversion
          </p>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center pb-4">
        <div className="row d-flex align-items-center">
          <div className="col-auto">
            <label className="me-2 fs-5" htmlFor="qualityInput">
              Quality 0-1:
            </label>
          </div>
          <div className="col-auto">
            <input
              type="number"
              id="qualityInput"
              min="0.00"
              max="1.00"
              step="0.01"
              value={inputQuality}
              onChange={handleInputChange}
              className="form-control"
              style={{ width: "100px" }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center pb-3">
        <div className="row">
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            className="form-control"
            multiple
            onChange={(e) => handleFiles(e.target.files)}
          />
        </div>
      </div>
      {images.length > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <div className="row ">
            <p class="mb-0 fw-bold fst-italic">
              Click the download icon to convert to WebP and download...
            </p>
          </div>
        </div>
      )}
      <br></br>
      {images.length > 0 && (
        <>
          {images.map((image, index) => (
            <DownloadBox key={index} image={image} />
          ))}
        </>
      )}
      {images.length > 0 && (
        <>
          <div className="d-flex align-items-center justify-content-center pt-5">
            <button
              type="button"
              className="btn btn-primary fs-4"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default SnippyArea;
