import SnippyArea from "./SnippyArea";

export default function Container() {
  return (
    <>
      <div className="container-hero d-flex border-bottom">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent sticky-top">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarText">
              <a
                href="#1"
                className="navbar-brand me-auto px-0 px-md-3 mb-2 mb-lg-0"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/weppi_symbol_logo.svg`}
                  alt="Weppi Logo"
                  className="py-3"
                  width="60px"
                />
              </a>
              <h1>Weppi</h1>
              <ul className="navbar-nav me-auto px-0 px-md-3 mb-2 mb-lg-0 justify-content-end w-100">
                <li className="nav-item">Free WebP Image Conversion!!!</li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="container bg-white p-1">
        <div className="row">
          <h1 className="lead fw-bold fs-1 text-center py-2 my-4">
            Convert your images to WebP now!
          </h1>
        </div>
        <SnippyArea />
      </div>
      <div className="container bg-white p-1">
        <div className="row">
          <p className="fst-italic text-center my-3">
            No uploads, no cookies, no tracking—your privacy is our top
            priority. Simply drag, drop, and convert in seconds, all on your own
            device.
          </p>
        </div>
      </div>
      <div className="container bg-white p-1">
        <div className="row my-5 text-start">
          <h2>Tell me more about WebP?</h2>
          <p>
            You should unlock the power of WebP for your images! WebP format
            offers superior compression without compromising on quality, making
            your images load faster while using less storage space. It's perfect
            for optimizing websites, improving user experience, and saving
            bandwidth. With WebP, you get crystal-clear visuals at a fraction of
            the file size compared to JPEG or PNG. Plus, it supports both
            lossless and lossy compression, giving you the flexibility to
            balance quality and performance. If you're looking to speed up your
            site or save space on your device, WebP is the smart choice for
            modern web and mobile platforms!
          </p>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-4 text-center mr-3">
              <img
                src={`${process.env.PUBLIC_URL}/images/weppi_symbol_logo.svg`}
                alt="Weppi Logo"
                className="img-fluid p-2 mb-4"
                width="100px"
              />
            </div>
            <div className="col-md-8 text-start mr-3">
              <p className="lead fw-bold">Modernise your images</p>
              <p>
                WebP format offers superior compression without compromising on
                quality, making your images load faster while using less storage
                space. It's perfect for optimizing websites, improving user
                experience, and saving bandwidth.
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="container-fluid bg-dark text-white border-top border-light">
        <div className="container">
          <div className="row py-2">
            <div className="d-flex navbar-text justify-content-between w-100">
              <p className="fw-bold mb-0 pb-0">
                &copy;{" "}
                <a href="https://lifecode.work" className="text-light">
                  Lifecode.Work
                </a>
              </p>
              <span className="text-end">
                <i className="bi bi-facebook me-3"></i>
                <i className="bi bi-instagram me-3"></i>
                <i className="bi bi-twitter-x"></i>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
